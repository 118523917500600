import analytics, { AnalyticsProperties } from 'libs/analytics';
import { addHeaderToAllAPIRequests, APIClient } from 'models/APIClient';
import { getDeviceId } from 'models/device_id';
import React from 'react';
import { UAParser } from 'ua-parser-js';

const userAgent = new UAParser().getResult();

// This is a subset of the
interface DeviceParams {
  id: string,

  platform: 'react',
  platform_version: string,

  os?: string,
  os_version?: string,
  device_model_name?: string,
  device_brand?: string,
  device_manufacturer?: string,
  device_ui_type?: string,

  device_screen_width?: number,
  device_screen_height?: number,
  device_screen_scale?: number,

  browser_name?: string,
  browser_version?: string,
  browser_engine_name?: string,
  browser_engine_version?: string,

  timezone: string,
  timezone_utc_offset: number,
  locale: string,
  user_agent: string,
  accept_language?: string,

  posted_at: Date,
}

interface Device extends DeviceParams {
  update_id: string,
  created_at: Date,
  updated_at: Date,
}

const getDeviceParams = (): DeviceParams => {
  return {
    id: getDeviceId(),
    platform: 'react',
    platform_version: React.version,

    os: userAgent.os.name,
    os_version: userAgent.os.version,
    device_model_name: userAgent.device.model,
    device_brand: userAgent.device.vendor,
    device_manufacturer: userAgent.device.vendor,
    device_ui_type: userAgent.device.type,

    device_screen_width: window.innerWidth,
    device_screen_height: window.innerHeight,
    device_screen_scale: window.devicePixelRatio,

    browser_name: userAgent.browser.name,
    browser_version: userAgent.browser.version,
    browser_engine_name: userAgent.engine.name,
    browser_engine_version: userAgent.engine.version,

    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timezone_utc_offset: new Date().getTimezoneOffset() / -60,
    locale: navigator.language,
    user_agent: userAgent.ua,
    accept_language: navigator.languages.join(', '),

    posted_at: new Date(),
  };
};

const userPropertiesForDeviceParams = (deviceParams: DeviceParams): AnalyticsProperties => {
  return {
    device_platform: deviceParams.platform,
    device_platform_version: deviceParams.platform_version,
    device_os: deviceParams.os,
    device_os_version: deviceParams.os_version,

    device_model_name: deviceParams.device_model_name,
    device_brand: deviceParams.device_brand,
    device_manufacturer: deviceParams.device_manufacturer,

    device_screen_width: deviceParams.device_screen_width,
    device_screen_height: deviceParams.device_screen_height,
    device_screen_scale: deviceParams.device_screen_scale,

    browser_name: deviceParams.browser_name,
    browser_version: deviceParams.browser_version,
    browser_engine_name: deviceParams.browser_engine_name,
    browser_engine_version: deviceParams.browser_engine_version,

    timezone: deviceParams.timezone,
    timezone_utc_offset: deviceParams.timezone_utc_offset,
    locale: deviceParams.locale,
  };
};

export const postDevice = async (): Promise<Device | undefined> => {
  const deviceParams = getDeviceParams();

  const userProperties = userPropertiesForDeviceParams(deviceParams);
  analytics.updateUserProperties(userProperties);

  const response = await APIClient.post<{ device: Device }>('/device', { device: deviceParams });
  if (APIClient.didSucceed(response)) {
    return response.successRes.data.device;
  }
  // TODO: Handle errors? Maybe only the first time ever, when success is critical for all subsequent API calls
  return undefined;
};

addHeaderToAllAPIRequests(async () => ({
  'x-mental-device-id': getDeviceId(),
}));
