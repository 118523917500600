import { formatCents } from 'libs/formatting';
import _ from 'lodash';
import { APIClient } from 'models/APIClient';
import Stripe from 'stripe';
import {
  LegacyMentalStripePrice, MentalPaywallPrice, MentalPaywallPricePeriodType, MentalPaywallPriceRawMetadata,
} from 'types/stripe';

type StripeCheckoutSession = {
  url: string,
};

export const createStripeCheckoutSession = async (promotion_code: string | undefined, price_key: string): Promise<StripeCheckoutSession | undefined> => {
  const response = await APIClient.post<{ stripe_checkout_session: StripeCheckoutSession }>('/stripe/checkout/session', { promotion_code, price_key });

  if (!APIClient.didSucceed(response)) return undefined;

  const { data } = response.successRes;
  const { stripe_checkout_session } = data;
  return stripe_checkout_session;
};

export const createStripeCheckoutSessionForGuest = async (email: string, promotion_code: string | undefined, price_key: string, is_lifecycle?: boolean): Promise<StripeCheckoutSession | undefined> => {
  const response = await APIClient.post<{ stripe_checkout_session: StripeCheckoutSession }>('/stripe/checkout/guest-session', {
    promotion_code, price_key, email, is_lifecycle,
  });

  if (!APIClient.didSucceed(response)) return undefined;

  const { data } = response.successRes;
  const { stripe_checkout_session } = data;
  return stripe_checkout_session;
};

export const linkStripeCheckoutSessionToUser = async (stripe_checkout_session_id: string): Promise<{ price: Stripe.Price } | undefined> => {
  const response = await APIClient.post('/stripe/checkout/session/success', { stripe_checkout_session_id });

  if (!APIClient.didSucceed(response)) return undefined;

  const { data } = response.successRes;

  return data;
};

export const linkStripeCheckoutSessionToUserAsGuest = async (stripe_checkout_session_id: string): Promise<{ price: Stripe.Price } | undefined> => {
  const response = await APIClient.post('/stripe/checkout/session/success-as-guest', { stripe_checkout_session_id });

  if (!APIClient.didSucceed(response)) return undefined;

  const { data } = response.successRes;

  return data;
};

export const fetchGuestCheckoutSession = async (stripe_checkout_session_id: string | undefined): Promise<{ price: Stripe.Price } | undefined> => {
  const response = await APIClient.get('/stripe/checkout/session', { stripe_checkout_session_id }, undefined);

  if (APIClient.didSucceed(response)) {
    return response.successRes.data;
  }
};

// Do this BEFORE they configure their payment method using Stripe Elements
export const createStripeSetupIntent = async (params: {
  email: string,
  promotion_code: string | undefined,
  price_key: string,
  is_lifecycle: boolean,
}): Promise<{ client_secret: string, success_url: string, setup_intent_id: string, customer_id: string } | undefined> => {
  const response = await APIClient.post('/stripe/setup-intent', params);
  if (!APIClient.didSucceed(response)) return undefined;
  return response.successRes.data;
};

// Do this AFTER they configure their payment method using Stripe Elements
export const createStripeSubscription = async (params: {
  email: string,
  setup_intent_id: string,
  tune_transaction_id: string | undefined,
  deal: string | undefined,
}) => {
  const response = await APIClient.post<{ price: Stripe.Price }>('/stripe/subscription', params);

  if (!APIClient.didSucceed(response)) {
    throw new Error('Failed to create stripe subscription');
  }

  return response.successRes.data;
};

// Do this AFTER they log in and have a user
export const linkStripeSetupIntentSubscriptionToUser = async (setup_intent_id: string, tune_transaction_id: string | undefined) => {
  const response = await APIClient.post('/stripe/subscription/link-to-user', { setup_intent_id, tune_transaction_id });
  if (!APIClient.didSucceed(response)) {
    throw new Error('Failed to link stripe setup intent subscription to user');
  }
};

// This is a summary of the raw stripe object, optimized for the frontend
export interface PromotionCode {
  id: string,
  code: string,

  metadata: {
    [name: string]: string,
  },

  amount_off: number | undefined,
  percent_off: number | undefined,
  duration: 'once' | 'forever',

  free_trial: {
    free_trial_only: boolean,
    duration_days: number | undefined,
    duration_months: number | undefined,
  },

  restrictions: {
    // expiry
    expires_at: string | undefined,
    is_expired: boolean,

    // exhaustion
    max_redemptions: number | undefined,
    times_redeemed: number,
    is_exhausted: boolean,
  },
}

export const displayPriceElements = (price: Stripe.Price): { text: string, size: 'small' | 'large' }[] => {
  const amount = price.unit_amount || 0;
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formatted = formatter.format(amount / 100);
  const [dollars, cents] = formatted.split('.');

  const hasCents = cents !== '00';

  return _.compact([
    { text: '$', size: 'small' },
    { text: dollars, size: 'large' },
    hasCents ? { text: `.${cents}`, size: 'small' } : undefined,
  ]);
};

export const displayPrice = (price: Stripe.Price): string => {
  const elements = displayPriceElements(price);
  return elements.map((e) => e.text).join('');
};

const formatPriceObject = (price: Stripe.Price, overrideCouponId?: string) => {
  // stripe returns price in cents
  const perIntervalPlanCost = ((price.unit_amount ?? 0) / 100);
  const { interval, interval_count } = price.recurring || {};
  if (!interval_count) return null;

  const couponId = overrideCouponId ?? price.metadata.coupon_id;
  const price_cents = price.unit_amount || 0;

  return {
    // this not being called lookup_key is completely stupid and confusing
    // one more reason to kill this LegacyMentalStripePrice type
    id: price.lookup_key ?? '5999_usd',

    mental_price_type: 'legacy_stripe_price',
    name: `${interval_count} ${interval}${interval_count > 1 ? 's' : ''}`,
    price: formatCents(price?.unit_amount || 0, price.currency),
    price_cents,
    savings: 0,
    perMonth: interval === 'year' ? (perIntervalPlanCost / 12) : perIntervalPlanCost,
    perDay: perIntervalPlanCost / (interval_count * (interval === 'year' ? 365 : 30)),
    coupon: couponId,
    index: price.metadata.index ? parseInt(price.metadata.index, 10) : 1,
    interval_count,
    interval,
    popular: price.metadata?.popular === 'true',
    amount: price.unit_amount,
  };
};

export const getCustomerByEmail = async (stripe: Stripe, email: string): Promise<Stripe.Customer | null> => {
  try {
    const customers = await stripe.customers.list({
      email,
      limit: 1,
    });

    return customers.data.length ? customers.data[0] : null;
  } catch (err) {
    console.error('Error fetching customer by email:', err);
    throw err;
  }
};

// Convert to arrow function and export as the others, also include stripe as parameter
export const hasCustomerUsedCoupon = async (stripe: Stripe, email: string, couponId: string): Promise<boolean> => {
  try {
    const customer = await getCustomerByEmail(stripe, email);

    if (!customer) {
      return false;
    }

    // Retrieve all subscriptions for the customer
    const subscriptions = await stripe.subscriptions.list({
      customer: customer.id,
      status: 'all',
      limit: 10,
    });

    for (const subscription of subscriptions.data) {
      if (subscription.discount?.coupon?.id === couponId) {
        return true;
      }
    }

    return false;
  } catch (err) {
    console.error('Error checking coupon usage:', err);
    throw err;
  }
};

export const getPriceBundleWithDiscount = async (stripe: Stripe, bundleKey: string, couponId: string): Promise<LegacyMentalStripePrice[]> => {
  // Fetch all products
  const allProducts = await stripe.products.list({
    limit: 5, // doesn't need to return that much products since we only handle 1 Mental Pro product item.
  });

  // Filter products by metadata
  const [product] = allProducts.data.filter((product) => product.metadata.entitlement && product.metadata.entitlement === 'mental_pro');

  const prices = await stripe.prices.list({
    product: product.id,
    active: true,
  });

  const bundle = prices.data.filter((p) => p.metadata.bundle === bundleKey);

  const result = await Promise.all(bundle.map((p) => formatPriceObjectWithDiscount(p, stripe, couponId)));

  return _.sortBy(result, ['index']);
};

export const getStripePriceByKey = async (stripe: Stripe, key: string) => {
  // Fetch all products
  const allProducts = await stripe.products.list({
    limit: 5, // doesn't need to return that much products since we only handle 1 Mental Pro product item.
  });

  // Filter products by metadata
  const [product] = allProducts.data.filter((product) => product.metadata.entitlement && product.metadata.entitlement === 'mental_pro');

  const prices = await stripe.prices.list({
    product: product.id,
    active: true,
  });

  return prices.data.find(
    (p) => p.metadata.price === key,
  );
};

export const formatPriceObjectWithDiscount = async (price: Stripe.Price, stripe: Stripe, overrideCouponId?: string): Promise<LegacyMentalStripePrice> => {
  const formattedPrice = formatPriceObject(price, overrideCouponId);
  const couponId = overrideCouponId ?? price.metadata.coupon_id;

  const price_cents = price.unit_amount || 0;
  let priceWithDiscount = price_cents;
  let discount = 0;
  let amountWithDiscountCents = price_cents;

  if (couponId) {
    const coupon = await stripe.coupons.retrieve(couponId);

    if (coupon) {
      if (coupon.percent_off) {
        discount = coupon.percent_off;
        amountWithDiscountCents = Math.round(price_cents * (1 - discount / 100));
      } else if (coupon.amount_off) {
        discount = (coupon.amount_off / price_cents) * 100;
        amountWithDiscountCents = price_cents - coupon.amount_off;
      }
      priceWithDiscount = amountWithDiscountCents;
    }
  }

  const currentPriceInCents = amountWithDiscountCents / 100;
  const interval_count = price.recurring?.interval_count ?? 1;
  const isYearly = price.recurring?.interval === 'year';

  return {
    ...formattedPrice,
    mental_price_type: 'legacy_stripe_price',
    priceWithDiscount: formatCents(priceWithDiscount, price.currency),
    discount,
    perMonthWithDiscount: currentPriceInCents / (interval_count * (isYearly ? 12 : 1)),
    perDayWithDiscount: currentPriceInCents / (interval_count * (isYearly ? 365 : 30)),
    amountWithDiscountCents,
  };
};

export const fetchPromotionCode = async (promotion_code: string | undefined): Promise<PromotionCode | null> => {
  if (!promotion_code) return null;
  const response = await APIClient.get<{ promotion_code: PromotionCode }, { promotion_code: string }>('/stripe/checkout/promotion_code', { promotion_code }, undefined);
  if (APIClient.didSucceed(response)) {
    return response.successRes.data.promotion_code;
  }
  return null;
};

export const fetchStripePriceForKey = async (lookup_key: string, stripe: Stripe, isUSD48OffDeal: boolean) => {
  const yearlyPriceFilter = await stripe.prices.list({
    lookup_keys: [lookup_key],
  });
  const price = yearlyPriceFilter.data[0];
  const overrideCouponId = isUSD48OffDeal ? price.metadata.tune_coupon_id : undefined;
  return formatPriceObjectWithDiscount(price, stripe, overrideCouponId);
};

// A sample URL to use for testing localhost:
// http://localhost:3000/get-mental-discount?key=5999_usd_straighout_purchase&coupon=MOqAoTps&email=tyler%2B023948%40getmental.com

export const fetchStripeOnboardingPrices = async (stripe: Stripe): Promise<LegacyMentalStripePrice[]> => {
  // Fetch all products
  const allProducts = await stripe.products.list({
    limit: 5, // doesn't need to return that much products since we only handle 1 Mental Pro product item.
  });

  // Filter products by metadata
  const [product] = allProducts.data.filter((product) => product.metadata.entitlement && product.metadata.entitlement === 'mental_pro');

  const prices = await stripe.prices.list({
    product: product.id,
    active: true,
  });

  const formattedPrices = _.sortBy(prices?.data?.map((p) => {
    if (p.metadata?.onboarding_selectable === 'true') {
      return formatPriceObject(p);
    }
    return null;
  }), ['index']);

  const fetchCouponsPromise = formattedPrices.map(async (price) => {
    if (price?.coupon) {
      const c = await stripe.coupons.retrieve(price.coupon);
      return {
        [price.id]: {
          percent_off: c.percent_off,
        },
      };
    }
    return {};
  });

  const discountMap = (await Promise.all(fetchCouponsPromise)).reduce((acc, curr) => {
    return { ...acc, ...curr }; // Merge all objects into one
  }, {});

  return _.compact(formattedPrices.map((price) => {
    if (!price) return null; // Ensure price is not undefined or null
    const discount = discountMap[price.id]; // Access using price.id as key
    const discountPercent = discount?.percent_off || 0;
    const discountAmount = Math.round(price.price_cents * (discountPercent / 100));
    const priceWithDiscount = price.price_cents - discountAmount;
    const currentPriceInCents = ((priceWithDiscount ?? 0) / 100);
    const amountWithDiscountCents = Math.round(price.price_cents - discountAmount);

    return {
      ...price,
      mental_price_type: 'legacy_stripe_price',
      discount: discount?.percent_off ?? undefined,
      priceWithDiscount: formatCents(priceWithDiscount),
      perMonthWithDiscount: (currentPriceInCents / price.interval_count) / (price.interval === 'year' ? 12 : 1),
      perDayWithDiscount: (currentPriceInCents / price.interval_count) / (price.interval === 'year' ? 365 : 30),
      amountWithDiscountCents,
    };
  }));
};

export const convertRawStripePriceToMentalPaywallPrice = (stripePrice: Stripe.Price): MentalPaywallPrice | null => {
  if (!stripePrice.metadata?.shows_on_therapy_paywall) {
    return null;
  }

  const metadata = stripePrice.metadata as MentalPaywallPriceRawMetadata;
  const periodType = metadata.period_type as MentalPaywallPricePeriodType | undefined;
  const trialPeriodDays = parseInt(metadata.trial_period_days || '0', 10);
  const isFreeTrial = trialPeriodDays > 0;
  return {
    ...stripePrice,
    mental_price_type: 'paywall_price',
    period_type: periodType,
    trial_period_days: trialPeriodDays,
    is_free_trial: isFreeTrial,
    is_subscription: metadata.is_subscription === 'true',
  };
};

export const fetchStripeTherapyPaywallPrices = async (stripe: Stripe): Promise<MentalPaywallPrice[]> => {
  // Get the mental_pro product
  const allProducts = await stripe.products.list();
  const [product] = allProducts.data.filter((product) => product.metadata.entitlement && product.metadata.entitlement === 'mental_pro');

  const pricesResponse = await stripe.prices.list({
    product: product.id,
    active: true,
  });
  const prices = pricesResponse.data;

  const selectedPrices = _.filter(prices, (p) => {
    return p.metadata?.shows_on_therapy_paywall === 'true';
  });

  const mentalPrices = _.compact(selectedPrices.map((p) => convertRawStripePriceToMentalPaywallPrice(p)));

  // Order by period_type: weekly -> two_months -> lifetime
  return _.orderBy(mentalPrices, [(price) => {
    const periodOrder: Record<string, number> = {
      weekly: 1,
      two_months: 2,
      lifetime: 3,
    };
    return periodOrder[price.period_type || ''] || 99;
  }]);
};
