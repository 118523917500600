import { postDevice } from 'models/device';
import React from 'react';

const useAPIPage = (): boolean => {
  const [didPostDevice, setDidPostDevice] = React.useState(false);
  React.useEffect(() => {
    postDevice().then(() => {
      setDidPostDevice(true);
    });
  }, []);
  return didPostDevice;
};
export default useAPIPage;
