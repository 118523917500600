export const stripNonnumerics = (str: string) => str.replace(/\D/g, '');

export const ordinalSuffix = (num: number) => {
  const j = num % 10;
  const k = num % 100;
  if (j === 1 && k !== 11) return 'st';
  if (j === 2 && k !== 12) return 'nd';
  if (j === 3 && k !== 13) return 'rd';
  return 'th';
};

export const formatCents = (numCents: number, currency = 'USD', includesCentsIfZero = true) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: includesCentsIfZero ? 2 : 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(numCents / 100); // Dividing by 100 to convert to dollars (or the main currency unit)
};

export const formatDollars = (numDollars: number | undefined, currency = 'USD') => {
  if (!numDollars) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(numDollars); // Dividing by 100 to convert to dollars (or the main currency unit)
};
